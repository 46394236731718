import React from "react";
// import ContainerCardForm from "./ContainerCardForm";
// import { Link } from "react-router-dom";
import Weworkwith from "./Weworkwith";
import CSIDivisions from "./CSIDivisions";
import WhyChooseQto from "./WhyChooseQto";

export default function Services() {
  return (
    <>
      <section className="services">
        <div className="main-div">
          <div className="backimg">
            <div className="colorgradientonimage">
              <h2 style={{ fontSize: "4rem" }}>Welcome to QTO Solutions</h2>
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "Poppins-SemiBold",
                  fontSize: "16px",
                }}
              >
                "Trust QTO Solutions to fine-tune your estimates, providing the
                edge you need to win lucrative contracts confidently"
              </p>
            </div>
          </div>
          <div className="container what-is-qto">
            <div className="row border-2">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 WIQTO">
                <h1>WHAT IS QTO?</h1>
                <p style={{ textAlign: "justify" }}>
                  Numerous contractors are currently preoccupied with ongoing
                  projects, leaving them with insufficient time to bid on new
                  jobs. Consequently, failing to bid on potential projects could
                  result in loss for your company. A rough approximation
                  suggests that contractors lose roughly $150,000 yearly due to
                  inaccurate estimates and missed bids.
                  <br />
                  <br />
                  For small contractors, employing a full-time construction
                  estimator can prove to be financially challenging. To solve
                  your construction cost estimation requirements, consider
                  utilizing a QTO estimating service tailored for builders.
                </p>
              </div>
              <div
                className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-7
               qto_benifits"
              >
                <div className="row benifit-container">
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 benifit-container">
                    <div className="benifit-a">
                      <img className="fl" alt="" src="./images/savemoney.png" />
                      <h2>Save Money</h2>
                    </div>

                    <p>
                      You can save money by getting help with estimating your
                      construction costs from us instead of hiring a full-time
                      estimator. Our services are offered at a nominal fee for
                      both full and trade-specific estimates, allowing you to
                      save money.
                    </p>
                  </div>

                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 benifit-container">
                    <div className="benifit-a">
                      <img className="fl" alt="" src="./images/savemoney.png" />
                      <h2>Fast Turnaround</h2>
                    </div>

                    <p>
                      Our team of certified estimators specializes in preparing
                      construction estimates. Working collaboratively allows us
                      to deliver estimates in a timely manner, ensuring that you
                      meet your deadline..
                    </p>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 benifit-container">
                    <div className="benifit-a">
                      <img className="fl" alt="" src="./images/savemoney.png" />
                      <h2>Quick Support</h2>
                    </div>

                    <p>
                      Our team of professional cost estimators is available 24/7
                      to address any inquiries you may have via phone or email.
                      We offer ongoing support and revisions, even after
                      delivering the estimates to ensure your satisfaction.
                    </p>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 benifit-container">
                    <div className="benifit-a">
                      <img className="fl" alt="" src="./images/savemoney.png" />
                      <h2>Accuracy Insured</h2>
                    </div>

                    <p>
                      Our estimates comply with local construction
                      specifications, material, and labor rates. By utilizing
                      state-of-the-art software such as PlanSwift and Bluebeam,
                      we guarantee our clients receive the most precise cost
                      estimates for their project.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"></div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"></div>
          </div>
        </div>
        <br></br>
        <br></br>
        <br></br>
        <div className="ourServices container">
          <h2 className="pageheading">Our Solutions</h2>
          <p style={{ textAlign: "justify" }}>
            QTO Solutions provides a range of accurate and affordable quantity
            takeoff services and construction estimating support services to
            organizations across the construction industry. We specialize in all
            construction trades and CSI divisions related to:
          </p>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 OSThree ">
              <div className="commercial-construction cc"></div>
              <div className="fedral-construction cc"></div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4  OSThree">
              <div className="residential-construction cc"></div>
              <div className="hcivil-construction cc"></div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 OSThree ">
              <div className="indust-construction col-larg"></div>
            </div>
          </div>
        </div>

        <CSIDivisions />

        <div className="hiv container">
          <h1>How it works</h1>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
              <p>
                Our certified take-off specialists use a structured process to
                accurately calculate the quantities of various construction
                materials required for each project. Here's a brief overview of
                how our construction take-off services work:
              </p>
              <img className="" alt="" src="./images/hiw.png" />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 estimator">
              <img className="fl" alt="" src="./images/Mask group.png" />
              <img className="f2" alt="" src="./images/Maskgroup.png" />
            </div>
          </div>
        </div>
        <div className="WCU">{/* Why Chose US */}</div>

        <WhyChooseQto />
        <Weworkwith />
      </section>
    </>
  );
}

import React from "react";

export default function EstmationServices() {
  return (
    <>
      <div className="ES">
        <div className="ourServices">
          <h2>Estimation Services We Offer</h2>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Poppins-SemiBold",
              fontSize: "16px",
            }}
          >
            Trust QTO Solutions to
            fine-tune your estimates, providing the edge you need to win
            lucrative contracts confidently.
          </p>
        </div>
        <div className="container ESL">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 d-flex justify-content-center">
              <div className="ESD ">
                <img className="ESM" alt="" src="./images/concrete.png" />
                <h2 className="">Concrete Estimation</h2>
                <p>
                  Our concrete estimates include quantities for foundations,
                  concrete pavements, retaining walls, slabs, beams, columns etc
                  {/* etc., covering concrete, rebar, and formwork. */}
                </p>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 d-flex justify-content-center">
              <div className="ESD ">
                <img className="ESM" alt="" src="./images/MASONARY.png" />
                <h2 className="">Masonary Estimation</h2>
                <p>
                  Our Concrete estimates include concrete, rebar and formwork
                  quantities for foundations, concrete pavements, retaining
                  walls, slabs, beam, columns etc.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 d-flex justify-content-center">
              <div className="ESD ">
                <img className="ESM" alt="" src="./images/DRYWALL.png" />
                <h2 className="">Drywall Estimation</h2>
                <p>
                  Our drywall estimating service includes counts for drywall
                  sheets, quantities of metal studs, as well as taping, mudding,
                  joint compounds, etc.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 d-flex justify-content-center">
              <div className="ESD ">
                <img className="ESM" alt="" src="./images/LAMBER.png" />
                <h2 className="">Lumber Estimation</h2>
                <p>
                  Our lumber takeoff service includes all the lumber framing,
                  encompassing wall studs, joists, rafters, floor beams, PSLs,
                  LVLs etc.
                  {/* wood decking, and plywood sheathing */}
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 d-flex justify-content-center">
              <div className="ESD ">
                <img className="ESM" alt="" src="./images/PAINT.png" />
                <h2 className="">Paint Estimation</h2>
                <p>
                  Our detailed painting estimates include priming, scraping, and
                  painting for walls, ceilings, doors, metal railings, etc.
                  Additionally.
                   {/* we provide estimates for special coatings. */}
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 d-flex justify-content-center">
              <div className="ESD ">
                <img className="ESM" alt="" src="./images/EARTH.png" />
                <h2 className="">Earthwork Estimation</h2>
                <p>
                  Our concrete estimates include quantities for foundations,
                  concrete pavements, retaining walls, slabs, beams, columns,
                  etc.
                  {/* , covering concrete, rebar, and formwork. */}
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 d-flex justify-content-center">
              <div className="ESD ">
                <img className="ESM" alt="" src="./images/FLOOR.png" />
                <h2 className="">Flooring Estimation</h2>
                <p>
                  Our Concrete estimates include concrete, rebar and formwork
                  quantities for foundations, concrete pavements, retaining
                  walls, slabs, beam, columns etc.
                </p>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 d-flex justify-content-center">
              <div className="ESD ">
                <img className="ESM" alt="" src="./images/ELEC.png" />
                <h2 className="">Electrical Estimating</h2>
                <p>
                  Our Concrete estimates include concrete, rebar and formwork
                  quantities for foundations, concrete pavements, retaining
                  walls, slabs, beam, columns etc.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

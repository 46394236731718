import React, { useState } from "react";
import Weworkwith from "./Weworkwith";
import AdvanceSearch from "./AdvanceSearch";

import CSIDivisions from "./CSIDivisions";
import Testimonials from "./Testimonials";
import Faqs from "./Faqs";
import EstmationServices from "./EstmationServices";
// import LatestProjects  from "./LatestProjects ";


export default function Home() {



  return (
    <>
      <AdvanceSearch /> 

      <section className=" container">
        <section>
          {/* Qto Numbering Available */}
          <div className="container qna">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                <ul className="qto-available">
                  <li className="border">
                    <img className="" alt="" src="./images/group1.svg" />
                    <span className="qtoA">QTO- Available</span>
                    <span>200</span>
                  </li>
                  <div></div>
                  <li className="border">
                    <img
                      className=""
                      alt=""
                      src="./images/qto-in-progress.png"
                    />
                    <span className="qtoA">QTO- In Progresse</span>
                    <span>81</span>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                <ul className="qto-available">
                  <li className="border">
                    <img className="" alt="" src="./images/bid-results.png" />
                    <span className="qtoA">Bid Results</span>
                    <span>50</span>
                  </li>
                  <li className="border">
                    <img className="" alt="" src="./images/group1.svg" />
                    <span className="qtoA">Private project</span>
                    <span>200</span>
                  </li>
                  <li className="border">
                    <img className="" alt="" src="./images/awarded.png" />
                    <span className="qtoA">Awarded</span>
                    <span>100</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section>
          {/* Reacent Updates and Job Calender Section */}
          {/* <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                <div className=" ">
                  <div className="border RU">
                    <h2 className="">Recent Updates</h2>
                    <div className="cards">
                      <div className="cardheadingsect">
                        <span className="project_started">Project Started</span>
                        <span className="post_an_hour">Post an hour ago</span>
                        <h2>
                          HS 498 Health Arts Robotics and Technology High School{" "}
                        </h2>
                        <div className="loc">
                          <img
                            className="pdf"
                            alt=""
                            src="./images/location.png"
                          />
                          <span className="">
                            207-01 116th Avenue , Cambria Heights, NY 11411, USA
                          </span>
                        </div>
                      </div>
                      <div className="cardheadingsect">
                        <span className="project_started">Project Started</span>
                        <span className="post_an_hour">Post an hour ago</span>
                        <h2>
                          HS 498 Health Arts Robotics and Technology High School{" "}
                        </h2>
                        <div className="loc">
                          <img
                            className="pdf"
                            alt=""
                            src="./images/location.png"
                          />
                          <span className="">
                            207-01 116th Avenue , Cambria Heights, NY 11411, USA
                          </span>
                        </div>
                      </div>

                      <div className="cardheadingsect">
                        <span className="project_started">Project Started</span>
                        <span className="post_an_hour">Post an hour ago</span>
                        <h2>
                          HS 498 Health Arts Robotics and Technology High School{" "}
                        </h2>
                        <div className="loc">
                          <img
                            className="pdf"
                            alt=""
                            src="./images/location.png"
                          />
                          <span className="">
                            207-01 116th Avenue , Cambria Heights, NY 11411, USA
                          </span>
                        </div>
                      </div>
                      <div className="cardheadingsect">
                        <span className="project_started">Project Started</span>
                        <span className="post_an_hour">Post an hour ago</span>
                        <h2>
                          HS 498 Health Arts Robotics and Technology High School{" "}
                        </h2>
                        <div className="loc">
                          <img
                            className="pdf"
                            alt=""
                            src="./images/location.png"
                          />
                          <span className="">
                            207-01 116th Avenue , Cambria Heights, NY 11411, USA
                          </span>
                        </div>
                      </div>
                      <div className="cardheadingsect">
                        <span className="project_started">Project Started</span>
                        <span className="post_an_hour">Post an hour ago</span>
                        <h2>
                          HS 498 Health Arts Robotics and Technology High School{" "}
                        </h2>
                        <div className="loc">
                          <img
                            className="pdf"
                            alt=""
                            src="./images/location.png"
                          />
                          <span className="">
                            207-01 116th Avenue , Cambria Heights, NY 11411, USA
                          </span>
                        </div>
                      </div>
                      <div className="cardheadingsect">
                        <span className="project_started">Project Started</span>
                        <span className="post_an_hour">Post an hour ago</span>
                        <h2>
                          HS 498 Health Arts Robotics and Technology High School{" "}
                        </h2>
                        <div className="loc">
                          <img
                            className="pdf"
                            alt=""
                            src="./images/location.png"
                          />
                          <span className="">
                            207-01 116th Avenue , Cambria Heights, NY 11411, USA
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-8 col-xl-8 ">
                <div className="border RU JC">
                  <h2 className="">Job Calender</h2>
                  <div className="cards">
                    <div className="cardheadingsect">
                      <span className="bid-date">Bid Date: 29/05/2023</span>
                      <span className="time">2:00 PM</span>
                      <h2>
                        HS 498 Health Arts Robotics and Technology High School{" "}
                      </h2>
                      <div className="loc">
                        <img
                          className="pdf"
                          alt=""
                          src="./images/location.png"
                        />
                        <span className="">
                          207-01 116th Avenue , Cambria Heights, NY 11411, USA
                        </span>
                      </div>
                      <div className="aut-progress">
                        <span className="Authority">
                          {" "}
                          Better Park City Authority
                        </span>
                        <div className="progress-background">
                          <div className="qto-progress">
                            <span>
                              QTO in progress <span>50%</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cardheadingsect">
                      <span className="bid-date">Bid Date: 29/05/2023</span>
                      <span className="time">2:00 PM</span>
                      <h2>
                        HS 498 Health Arts Robotics and Technology High School{" "}
                      </h2>
                      <div className="loc">
                        <img
                          className="pdf"
                          alt=""
                          src="./images/location.png"
                        />
                        <span className="">
                          207-01 116th Avenue , Cambria Heights, NY 11411, USA
                        </span>
                      </div>
                      <div className="aut-progress">
                        <span className="Authority">
                          {" "}
                          Better Park City Authority
                        </span>
                        <div className="progress-background">
                          <div className="qto-progress">
                            <span>
                              QTO in progress <span>50%</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cardheadingsect">
                      <span className="bid-date">Bid Date: 29/05/2023</span>
                      <span className="time">2:00 PM</span>
                      <h2>
                        HS 498 Health Arts Robotics and Technology High School{" "}
                      </h2>
                      <div className="loc">
                        <img
                          className="pdf"
                          alt=""
                          src="./images/location.png"
                        />
                        <span className="">
                          207-01 116th Avenue , Cambria Heights, NY 11411, USA
                        </span>
                      </div>
                      <div className="aut-progress">
                        <span className="Authority">
                          {" "}
                          Better Park City Authority
                        </span>
                        <div className="progress-background">
                          <div className="qto-progress">
                            <span>
                              QTO in progress <span>50%</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cardheadingsect">
                      <span className="bid-date">Bid Date: 29/05/2023</span>
                      <span className="time">2:00 PM</span>
                      <h2>
                        HS 498 Health Arts Robotics and Technology High School{" "}
                      </h2>
                      <div className="loc">
                        <img
                          className="pdf"
                          alt=""
                          src="./images/location.png"
                        />
                        <span className="">
                          207-01 116th Avenue , Cambria Heights, NY 11411, USA
                        </span>
                      </div>
                      <div className="aut-progress">
                        <span className="Authority">
                          {" "}
                          Better Park City Authority
                        </span>
                        <div className="progress-background">
                          <div className="qto-progress">
                            <span>
                              QTO in progress <span>50%</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cardheadingsect">
                      <span className="bid-date">Bid Date: 29/05/2023</span>
                      <span className="time">2:00 PM</span>
                      <h2>
                        HS 498 Health Arts Robotics and Technology High School{" "}
                      </h2>
                      <div className="loc">
                        <img
                          className="pdf"
                          alt=""
                          src="./images/location.png"
                        />
                        <span className="">
                          207-01 116th Avenue , Cambria Heights, NY 11411, USA
                        </span>
                      </div>
                      <div className="aut-progress">
                        <span className="Authority">
                          {" "}
                          Better Park City Authority
                        </span>
                        <div className="progress-background">
                          <div className="qto-progress">
                            <span>
                              QTO in progress <span>50%</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cardheadingsect">
                      <span className="bid-date">Bid Date: 29/05/2023</span>
                      <span className="time">2:00 PM</span>
                      <h2>
                        HS 498 Health Arts Robotics and Technology High School{" "}
                      </h2>
                      <div className="loc">
                        <img
                          className="pdf"
                          alt=""
                          src="./images/location.png"
                        />
                        <span className="">
                          207-01 116th Avenue , Cambria Heights, NY 11411, USA
                        </span>
                      </div>
                      <div className="aut-progress">
                        <span className="Authority">
                          {" "}
                          Better Park City Authority
                        </span>
                        <div className="progress-background">
                          <div className="qto-progress">
                            <span>
                              QTO in progress <span>50%</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </section>
        <section>
          {/* Latest Projects  */}
          <div className="container">
            <div className="row"></div>
          </div>
        </section>
        <section>
          {/* why Choose QTo */}
          <div className="container">
            <div className="row"></div>
          </div>
        </section>

        {/* <LatestProjects />
        <Weworkwith />
        <ContainerCardForm />
        <CardContainer /> */}
      </section>
      {/* <LatestProjects /> */}
      <EstmationServices />
      {/* <Testimonials /> */}
      <Faqs /> 
      <CSIDivisions />
      <Weworkwith />
    </>
  );
}

// Footer.js
import React from "react";

const Footer = ({ images }) => {
  // Use the images array here as needed
  // For example, you can access the first image using `images[0]`

  return (
    <>
      <div className="row footer ">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <ul>
            <li className="footer-image">
              <img
                className="fl"
                alt=""
                src="./images/qto logo_new_footer.png"
              />
              <div>
                <img className="vfs" alt="" src="./images/verifysecured.png" />
              </div>
            </li>
            <br></br>
            <li>
              <p style={{ textAlign: "justify" }}>
                QTO Solutions is a Professional Estimation and Quantity Take-off
                Service Provider. We assist our clients in pain staking and
                timeconsuming involvement of Building Estimation With QTO
                Solutions,the bidding process is made simple!
              </p>
            </li>
            <li>
              <p>© 2024 QTO SOLUTIONS - ALL RIGHTS RESERVED.</p>
            </li>
          </ul>
          <ul>
            <li></li>
            <li></li>
          </ul>
        </div>

        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
          <ul>
            {/* <li>
              { <div className="search-container">
                <input
                  className="search-input"
                  type="text"
                  placeholder="Search..."
                />
              </div> }
            </li> */}
            <li>
              <p className="follow-us-text">Follow Us </p>
            </li>
            <li className="social_media_icon">
              <a href="https://www.instagram.com/qto_solutions/">
                <img
                  className="contact_footer"
                  alt=""
                  src="./images/instagram@2x.png"
                  title="Instagram"
                />
              </a>
              <a href="https://www.facebook.com/profile.php?id=100064318140416">
                <img
                  className="contact_footer"
                  alt=""
                  src="./images/facebook@2x.png"
                  title="Facebook"
                />
              </a>
              <a href="https://www.linkedin.com/in/qto-solutions-9a689a204/">
                <img
                  className="contact_footer"
                  alt=""
                  src="./images/twitter-circled@2x.png"
                  title="Linkedin"
                />
              </a>
              <a href="https://twitter.com/QtoSol">
                <img
                  className="contact_footer"
                  alt=""
                  src="./images/twitter-circled@2xx.png"
                  title="Linkedin"
                />
              </a>
            </li>
          </ul>
        </div>
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 fmac">
          <ul>
            <li>
              <img
                className="mailfooter"
                alt=""
                src="./images/mail_footer.png"
              />
              <a href="mailto:info@qtosol.com">
                <p>info@qtosol.com</p>
              </a>
            </li>
            <li>
              <img
                className="mailfooter"
                alt=""
                src="./images/contact_footer.png"
              />
              <a className="" href="tel: 571-748-4366">
                <p>(312)-544-0023</p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Footer;

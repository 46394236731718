import React from "react";

function Career() {
  return (
    <>
      <div className="carrier about">
        <div className="colorgradientonimage">
          <h2>Work with QTO Solutions</h2>
        </div>
      </div>
      <div className="tr-job-posted section-padding">
        <div className="container">
          <div className="job-tab text-center">
            {/* <ul className="nav nav-tabs justify-content-center" role="tablist">
              <li role="presentation" className="active">
                <a
                  className="active show"
                  href="#hot-jobs"
                  aria-controls="hot-jobs"
                  role="tab"
                  data-toggle="tab"
                  aria-selected="true"
                >
                  Hot Jobs
                </a>
              </li>
              <li role="presentation">
                <a
                  href="#recent-jobs"
                  aria-controls="recent-jobs"
                  role="tab"
                  data-toggle="tab"
                  className=""
                  aria-selected="false"
                >
                  Recent Jobs
                </a>
              </li>
              <li role="presentation">
                <a
                  href="#popular-jobs"
                  aria-controls="popular-jobs"
                  role="tab"
                  data-toggle="tab"
                  className=""
                  aria-selected="false"
                >
                  Popular Jobs
                </a>
              </li>
            </ul> */}
            <div className="tab-content text-left">
              <div
                role="tabpanel"
                className="tab-pane fade active show"
                id="hot-jobs"
              >
                <div className="row">
                  <div className="col-md-6 col-lg-3">
                    <div className="job-item">
                      <div className="item-overlay">
                        <div className="job-info">
                          <ul className="tr-list job-meta">
                            <li>
                              {" "}
                              <a href="#" className="btn btn-primary">
                                Full Time
                              </a>
                            </li>
                            <li>
                              <span className="tr-title">
                                <a href="#">Senior Estimator </a>
                                {/* <span>
                              <a href="#">Dig File</a>
                            </span> */}
                              </span>
                            </li>
                            <li>
                              <i
                                className="fa fa-map-signs"
                                aria-hidden="true"
                              />
                              Lahore, Punjab, Pakistan
                            </li>
                            <li>
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                              Mid Level
                            </li>
                            <li>
                              <i className="fa fa-money" aria-hidden="true" />
                              $5,000 - $6,000
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="job-info">
                        <div className="company-logo">
                          <img
                            src="./images/enhaned-image 11.png"
                            alt="images"
                            className="img-fluid"
                          />
                        </div>
                        <span className="tr-title">
                          <a href="#">Senior Estimator </a>
                          {/* <span>
                            <a href="#">Dig File</a>
                          </span> */}
                        </span>
                        <ul className="tr-list job-meta">
                          <li>
                            <span>
                              <i
                                className="fa fa-map-signs"
                                aria-hidden="true"
                              />
                            </span>
                            Lahore, Punjab, Pakistan
                          </li>
                          <li>
                            <span>
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                            </span>
                            Mid Level
                          </li>
                          <li>
                            <span>
                              <i className="fa fa-money" aria-hidden="true" />
                            </span>
                            $5,000 - $6,000
                          </li>
                        </ul>
                        <div className="time">
                          <a href="#">
                            <span>Full Time</span>
                          </a>
                          {/* <span className="pull-right">
                            Posted 23 hours ago
                          </span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="job-item">
                      <div className="item-overlay">
                        <div className="job-info">
                          <ul className="tr-list job-meta">
                            <li>
                              {" "}
                              <a href="#" className="btn btn-primary">
                                Full Time
                              </a>
                            </li>
                            <li>
                              <span className="tr-title">
                                <a href="#">Junnior Estimator </a>
                                {/* <span>
                              <a href="#">Dig File</a>
                            </span> */}
                              </span>
                            </li>
                            <li>
                              <i
                                className="fa fa-map-signs"
                                aria-hidden="true"
                              />
                              Lahore, Punjab, Pakistan
                            </li>
                            <li>
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                              Mid Level
                            </li>
                            <li>
                              <i className="fa fa-money" aria-hidden="true" />
                              $3,000 - $5,000
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="job-info">
                        <div className="company-logo">
                          <img
                            src="./images/sodapdf-converted.png"
                            alt="images"
                            className="img-fluid"
                          />
                        </div>
                        <span className="tr-title">
                          <a href="#">Junior Estimator </a>
                          {/* <span>
                            <a href="#">Dig File</a>
                          </span> */}
                        </span>
                        <ul className="tr-list job-meta">
                          <li>
                            <span>
                              <i
                                className="fa fa-map-signs"
                                aria-hidden="true"
                              />
                            </span>
                            Lahore, Punjab, Pakistan
                          </li>
                          <li>
                            <span>
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                            </span>
                            Mid Level
                          </li>
                          <li>
                            <span>
                              <i className="fa fa-money" aria-hidden="true" />
                            </span>
                            $3,000 - $5,000
                          </li>
                        </ul>
                        <div className="time">
                          <a href="#">
                            <span>Full Time</span>
                          </a>
                          {/* <span className="pull-right">
                            Posted 23 hours ago
                          </span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /.row */}
              </div>
              {/* /.tab-pane */}
              <div
                role="tabpanel"
                className="tab-pane fade in"
                id="recent-jobs"
              >
                <div className="row">
                  <div className="col-md-6 col-lg-3">
                    <div className="job-item">
                      <div className="item-overlay">
                        <div className="job-info">
                          <a href="#" className="btn btn-primary">
                            Part Time
                          </a>
                          <span className="tr-title">
                            <a href="#">Design Associate</a>
                            <span>
                              <a href="#">Loop</a>
                            </span>
                          </span>
                          <ul className="tr-list job-meta">
                            <li>
                              <i
                                className="fa fa-map-signs"
                                aria-hidden="true"
                              />
                              Lahore, Punjab, Pakistan
                            </li>
                            <li>
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                              Mid Level
                            </li>
                            <li>
                              <i className="fa fa-money" aria-hidden="true" />
                              $5,000 - $6,000
                            </li>
                          </ul>
                          <ul className="job-social tr-list">
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-heart-o"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-expand"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-bookmark-o"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-long-arrow-right"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="job-info">
                        <div className="company-logo">
                          <img
                            src="./images/sodapdf-converted.png"
                            alt="images"
                            className="img-fluid"
                          />
                        </div>
                        <span className="tr-title">
                          <a href="#">Design Associate</a>
                          <span>
                            <a href="#">Loop</a>
                          </span>
                        </span>
                        <ul className="tr-list job-meta">
                          <li>
                            <span>
                              <i
                                className="fa fa-map-signs"
                                aria-hidden="true"
                              />
                            </span>
                            Lahore, Punjab, Pakistan
                          </li>
                          <li>
                            <span>
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                            </span>
                            Mid Level
                          </li>
                          <li>
                            <span>
                              <i className="fa fa-money" aria-hidden="true" />
                            </span>
                            $5,000 - $6,000
                          </li>
                        </ul>
                        <div className="time">
                          <a href="#">
                            <span className="part-time">Part Time</span>
                          </a>
                          <span className="pull-right">Posted 1 day ago</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="job-item">
                      <div className="item-overlay">
                        <div className="job-info">
                          <a href="#" className="btn btn-primary">
                            Full Time
                          </a>
                          <span className="tr-title">
                            <a href="#">Project Manager</a>
                            <span>
                              <a href="#">Dig File</a>
                            </span>
                          </span>
                          <ul className="tr-list job-meta">
                            <li>
                              <i
                                className="fa fa-map-signs"
                                aria-hidden="true"
                              />
                              Lahore, Punjab, Pakistan
                            </li>
                            <li>
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                              Mid Level
                            </li>
                            <li>
                              <i className="fa fa-money" aria-hidden="true" />
                              $5,000 - $6,000
                            </li>
                          </ul>
                          <ul className="job-social tr-list">
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-heart-o"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-expand"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-bookmark-o"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-long-arrow-right"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="job-info">
                        <div className="company-logo">
                          <img
                            src="./images/sodapdf-converted.png"
                            alt="images"
                            className="img-fluid"
                          />
                        </div>
                        <span className="tr-title">
                          <a href="#">Project Manager</a>
                          <span>
                            <a href="#">Dig File</a>
                          </span>
                        </span>
                        <ul className="tr-list job-meta">
                          <li>
                            <span>
                              <i
                                className="fa fa-map-signs"
                                aria-hidden="true"
                              />
                            </span>
                            Lahore, Punjab, Pakistan
                          </li>
                          <li>
                            <span>
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                            </span>
                            Mid Level
                          </li>
                          <li>
                            <span>
                              <i className="fa fa-money" aria-hidden="true" />
                            </span>
                            $5,000 - $6,000
                          </li>
                        </ul>
                        <div className="time">
                          <a href="#">
                            <span>Full Time</span>
                          </a>
                          {/* <span className="pull-right">
                            Posted 23 hours ago
                          </span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="job-item">
                      <div className="item-overlay">
                        <div className="job-info">
                          <a href="#" className="btn btn-primary">
                            Part Time
                          </a>
                          <span className="tr-title">
                            <a href="#">Design Consultant</a>
                            <span>
                              <a href="#">Families</a>
                            </span>
                          </span>
                          <ul className="tr-list job-meta">
                            <li>
                              <i
                                className="fa fa-map-signs"
                                aria-hidden="true"
                              />
                              Lahore, Punjab, Pakistan
                            </li>
                            <li>
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                              Mid Level
                            </li>
                            <li>
                              <i className="fa fa-money" aria-hidden="true" />
                              $5,000 - $6,000
                            </li>
                          </ul>
                          <ul className="job-social tr-list">
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-heart-o"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-expand"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-bookmark-o"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-long-arrow-right"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="job-info">
                        <div className="company-logo">
                          <img
                            src="./images/sodapdf-converted.png"
                            alt="images"
                            className="img-fluid"
                          />
                        </div>
                        <span className="tr-title">
                          <a href="#">Design Consultant</a>
                          <span>
                            <a href="#">Families</a>
                          </span>
                        </span>
                        <ul className="tr-list job-meta">
                          <li>
                            <span>
                              <i
                                className="fa fa-map-signs"
                                aria-hidden="true"
                              />
                            </span>
                            Lahore, Punjab, Pakistan
                          </li>
                          <li>
                            <span>
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                            </span>
                            Mid Level
                          </li>
                          <li>
                            <span>
                              <i className="fa fa-money" aria-hidden="true" />
                            </span>
                            $5,000 - $6,000
                          </li>
                        </ul>
                        <div className="time">
                          <a href="#">
                            <span className="part-time">Part Time</span>
                          </a>
                          {/* <span className="pull-right">
                            Posted Oct 09, 2017
                          </span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="job-item">
                      <div className="item-overlay">
                        <div className="job-info">
                          <a href="#" className="btn btn-primary">
                            Freelance
                          </a>
                          <span className="tr-title">
                            <a href="#">Graphic Designer</a>
                            <span>
                              <a href="#">Humanity Creative</a>
                            </span>
                          </span>
                          <ul className="tr-list job-meta">
                            <li>
                              <i
                                className="fa fa-map-signs"
                                aria-hidden="true"
                              />
                              Lahore, Punjab, Pakistan
                            </li>
                            <li>
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                              Mid Level
                            </li>
                            <li>
                              <i className="fa fa-money" aria-hidden="true" />
                              $5,000 - $6,000
                            </li>
                          </ul>
                          <ul className="job-social tr-list">
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-heart-o"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-expand"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-bookmark-o"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-long-arrow-right"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="job-info">
                        <div className="company-logo">
                          <img
                            src="./images/sodapdf-converted.png"
                            alt="images"
                            className="img-fluid"
                          />
                        </div>
                        <span className="tr-title">
                          <a href="#">Graphic Designer</a>
                          <span>
                            <a href="#">Humanity Creative</a>
                          </span>
                        </span>
                        <ul className="tr-list job-meta">
                          <li>
                            <span>
                              <i
                                className="fa fa-map-signs"
                                aria-hidden="true"
                              />
                            </span>
                            Lahore, Punjab, Pakistan
                          </li>
                          <li>
                            <span>
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                            </span>
                            Mid Level
                          </li>
                          <li>
                            <span>
                              <i className="fa fa-money" aria-hidden="true" />
                            </span>
                            $5,000 - $6,000
                          </li>
                        </ul>
                        <div className="time">
                          <a href="#">
                            <span className="freelance">Freelance</span>
                          </a>
                          <span className="pull-right">Posted 10 day ago</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="job-item">
                      <div className="item-overlay">
                        <div className="job-info">
                          <a href="#" className="btn btn-primary">
                            Full Time
                          </a>
                          <span className="tr-title">
                            <a href="#">Design Associate</a>
                            <span>
                              <a href="#">Pencil</a>
                            </span>
                          </span>
                          <ul className="tr-list job-meta">
                            <li>
                              <i
                                className="fa fa-map-signs"
                                aria-hidden="true"
                              />
                              Lahore, Punjab, Pakistan
                            </li>
                            <li>
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                              Mid Level
                            </li>
                            <li>
                              <i className="fa fa-money" aria-hidden="true" />
                              $5,000 - $6,000
                            </li>
                          </ul>
                          <ul className="job-social tr-list">
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-heart-o"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-expand"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-bookmark-o"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-long-arrow-right"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="job-info">
                        <div className="company-logo">
                          <img
                            src="./images/sodapdf-converted.png"
                            alt="images"
                            className="img-fluid"
                          />
                        </div>
                        <span className="tr-title">
                          <a href="#">Design Associate</a>
                          <span>
                            <a href="#">Pencil</a>
                          </span>
                        </span>
                        <ul className="tr-list job-meta">
                          <li>
                            <span>
                              <i
                                className="fa fa-map-signs"
                                aria-hidden="true"
                              />
                            </span>
                            Lahore, Punjab, Pakistan
                          </li>
                          <li>
                            <span>
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                            </span>
                            Mid Level
                          </li>
                          <li>
                            <span>
                              <i className="fa fa-money" aria-hidden="true" />
                            </span>
                            $5,000 - $6,000
                          </li>
                        </ul>
                        <div className="time">
                          <a href="#">
                            <span>Full Time</span>
                          </a>
                          {/* <span className="pull-right">
                            Posted 23 hours ago
                          </span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="job-item">
                      <div className="item-overlay">
                        <div className="job-info">
                          <a href="#" className="btn btn-primary">
                            Freelance
                          </a>
                          <span className="tr-title">
                            <a href="#">Project Manager</a>
                            <span>
                              <a href="#">Sky Creative</a>
                            </span>
                          </span>
                          <ul className="tr-list job-meta">
                            <li>
                              <i
                                className="fa fa-map-signs"
                                aria-hidden="true"
                              />
                              Lahore, Punjab, Pakistan
                            </li>
                            <li>
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                              Mid Level
                            </li>
                            <li>
                              <i className="fa fa-money" aria-hidden="true" />
                              $5,000 - $6,000
                            </li>
                          </ul>
                          <ul className="job-social tr-list">
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-heart-o"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-expand"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-bookmark-o"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-long-arrow-right"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="job-info">
                        <div className="company-logo">
                          <img
                            src="./images/sodapdf-converted.png"
                            alt="images"
                            className="img-fluid"
                          />
                        </div>
                        <span className="tr-title">
                          <a href="#">Project Manager</a>
                          <span>
                            <a href="#">Sky Creative</a>
                          </span>
                        </span>
                        <ul className="tr-list job-meta">
                          <li>
                            <span>
                              <i
                                className="fa fa-map-signs"
                                aria-hidden="true"
                              />
                            </span>
                            Lahore, Punjab, Pakistan
                          </li>
                          <li>
                            <span>
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                            </span>
                            Mid Level
                          </li>
                          <li>
                            <span>
                              <i className="fa fa-money" aria-hidden="true" />
                            </span>
                            $5,000 - $6,000
                          </li>
                        </ul>
                        <div className="time">
                          <a href="#">
                            <span className="freelance">Freelance</span>
                          </a>
                          {/* <span className="pull-right">Posted 1 day ago</span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="job-item">
                      <div className="item-overlay">
                        <div className="job-info">
                          <a href="#" className="btn btn-primary">
                            Part Time
                          </a>
                          <span className="tr-title">
                            <a href="#">Design Consultant</a>
                            <span>
                              <a href="#">Owl</a>
                            </span>
                          </span>
                          <ul className="tr-list job-meta">
                            <li>
                              <i
                                className="fa fa-map-signs"
                                aria-hidden="true"
                              />
                              Lahore, Punjab, Pakistan
                            </li>
                            <li>
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                              Mid Level
                            </li>
                            <li>
                              <i className="fa fa-money" aria-hidden="true" />
                              $5,000 - $6,000
                            </li>
                          </ul>
                          <ul className="job-social tr-list">
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-heart-o"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-expand"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-bookmark-o"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-long-arrow-right"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="job-info">
                        <div className="company-logo">
                          <img
                            src="./images/sodapdf-converted.png"
                            alt="images"
                            className="img-fluid"
                          />
                        </div>
                        <span className="tr-title">
                          <a href="#">Design Consultant</a>
                          <span>
                            <a href="#">Owl</a>
                          </span>
                        </span>
                        <ul className="tr-list job-meta">
                          <li>
                            <span>
                              <i
                                className="fa fa-map-signs"
                                aria-hidden="true"
                              />
                            </span>
                            Lahore, Punjab, Pakistan
                          </li>
                          <li>
                            <span>
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                            </span>
                            Mid Level
                          </li>
                          <li>
                            <span>
                              <i className="fa fa-money" aria-hidden="true" />
                            </span>
                            $5,000 - $6,000
                          </li>
                        </ul>
                        <div className="time">
                          <a href="#">
                            <span className="part-time">Part Time</span>
                          </a>
                          {/* <span className="pull-right">Posted 10 day ago</span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="job-item">
                      <div className="item-overlay">
                        <div className="job-info">
                          <a href="#" className="btn btn-primary">
                            Full Time
                          </a>
                          <span className="tr-title">
                            <a href="#">Graphic Designer</a>
                            <span>
                              <a href="#">Fox</a>
                            </span>
                          </span>
                          <ul className="tr-list job-meta">
                            <li>
                              <i
                                className="fa fa-map-signs"
                                aria-hidden="true"
                              />
                              Lahore, Punjab, Pakistan
                            </li>
                            <li>
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                              Mid Level
                            </li>
                            <li>
                              <i className="fa fa-money" aria-hidden="true" />
                              $5,000 - $6,000
                            </li>
                          </ul>
                          <ul className="job-social tr-list">
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-heart-o"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-expand"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-bookmark-o"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-long-arrow-right"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="job-info">
                        <div className="company-logo">
                          <img
                            src="./images/sercivcesbg.png"
                            alt="images"
                            className="img-fluid"
                          />
                        </div>
                        <span className="tr-title">
                          <a href="#">Graphic Designer</a>
                          <span>
                            <a href="#">Fox</a>
                          </span>
                        </span>
                        <ul className="tr-list job-meta">
                          <li>
                            <span>
                              <i
                                className="fa fa-map-signs"
                                aria-hidden="true"
                              />
                            </span>
                            Lahore, Punjab, Pakistan
                          </li>
                          <li>
                            <span>
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                            </span>
                            Mid Level
                          </li>
                          <li>
                            <span>
                              <i className="fa fa-money" aria-hidden="true" />
                            </span>
                            $5,000 - $6,000
                          </li>
                        </ul>
                        <div className="time">
                          <a href="#">
                            <span>Full Time</span>
                          </a>
                          {/* <span className="pull-right">
                            Posted Oct 09, 2017
                          </span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /.row */}
              </div>
              {/* /.tab-pane */}
              <div
                role="tabpanel"
                className="tab-pane fade in"
                id="popular-jobs"
              >
                <div className="row">
                  <div className="col-md-6 col-lg-3">
                    <div className="job-item">
                      <div className="item-overlay">
                        <div className="job-info">
                          <a href="#" className="btn btn-primary">
                            Full Time
                          </a>
                          <span className="tr-title">
                            <a href="#">Graphic Designer</a>
                            <span>
                              <a href="#">Fox</a>
                            </span>
                          </span>
                          <ul className="tr-list job-meta">
                            <li>
                              <i
                                className="fa fa-map-signs"
                                aria-hidden="true"
                              />
                              Lahore, Punjab, Pakistan
                            </li>
                            <li>
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                              Mid Level
                            </li>
                            <li>
                              <i className="fa fa-money" aria-hidden="true" />
                              $5,000 - $6,000
                            </li>
                          </ul>
                          <ul className="job-social tr-list">
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-heart-o"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-expand"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-bookmark-o"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-long-arrow-right"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="job-info">
                        <div className="company-logo">
                          <img
                            src="./images/sodapdf-converted.png"
                            alt="images"
                            className="img-fluid"
                          />
                        </div>
                        <span className="tr-title">
                          <a href="#">Graphic Designer</a>
                          <span>
                            <a href="#">Fox</a>
                          </span>
                        </span>
                        <ul className="tr-list job-meta">
                          <li>
                            <span>
                              <i
                                className="fa fa-map-signs"
                                aria-hidden="true"
                              />
                            </span>
                            Lahore, Punjab, Pakistan
                          </li>
                          <li>
                            <span>
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                            </span>
                            Mid Level
                          </li>
                          <li>
                            <span>
                              <i className="fa fa-money" aria-hidden="true" />
                            </span>
                            $5,000 - $6,000
                          </li>
                        </ul>
                        <div className="time">
                          <a href="#">
                            <span>Full Time</span>
                          </a>
                          {/* <span className="pull-right">
                            Posted Oct 09, 2017
                          </span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="job-item">
                      <div className="item-overlay">
                        <div className="job-info">
                          <a href="#" className="btn btn-primary">
                            Part Time
                          </a>
                          <span className="tr-title">
                            <a href="#">Design Associate</a>
                            <span>
                              <a href="#">Loop</a>
                            </span>
                          </span>
                          <ul className="tr-list job-meta">
                            <li>
                              <i
                                className="fa fa-map-signs"
                                aria-hidden="true"
                              />
                              Lahore, Punjab, Pakistan
                            </li>
                            <li>
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                              Mid Level
                            </li>
                            <li>
                              <i className="fa fa-money" aria-hidden="true" />
                              $5,000 - $6,000
                            </li>
                          </ul>
                          <ul className="job-social tr-list">
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-heart-o"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-expand"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-bookmark-o"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-long-arrow-right"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="job-info">
                        <div className="company-logo">
                          <img
                            src="./images/sodapdf-converted.png"
                            alt="images"
                            className="img-fluid"
                          />
                        </div>
                        <span className="tr-title">
                          <a href="#">Design Associate</a>
                          <span>
                            <a href="#">Loop</a>
                          </span>
                        </span>
                        <ul className="tr-list job-meta">
                          <li>
                            <span>
                              <i
                                className="fa fa-map-signs"
                                aria-hidden="true"
                              />
                            </span>
                            Lahore, Punjab, Pakistan
                          </li>
                          <li>
                            <span>
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                            </span>
                            Mid Level
                          </li>
                          <li>
                            <span>
                              <i className="fa fa-money" aria-hidden="true" />
                            </span>
                            $5,000 - $6,000
                          </li>
                        </ul>
                        <div className="time">
                          <a href="#">
                            <span className="part-time">Part Time</span>
                          </a>
                          {/* <span className="pull-right">Posted 1 day ago</span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="job-item">
                      <div className="item-overlay">
                        <div className="job-info">
                          <a href="#" className="btn btn-primary">
                            Full Time
                          </a>
                          <span className="tr-title">
                            <a href="#">Project Manager</a>
                            <span>
                              <a href="#">Dig File</a>
                            </span>
                          </span>
                          <ul className="tr-list job-meta">
                            <li>
                              <i
                                className="fa fa-map-signs"
                                aria-hidden="true"
                              />
                              Lahore, Punjab, Pakistan
                            </li>
                            <li>
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                              Mid Level
                            </li>
                            <li>
                              <i className="fa fa-money" aria-hidden="true" />
                              $5,000 - $6,000
                            </li>
                          </ul>
                          <ul className="job-social tr-list">
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-heart-o"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-expand"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-bookmark-o"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-long-arrow-right"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="job-info">
                        <div className="company-logo">
                          <img
                            src="./images/sodapdf-converted.png"
                            alt="images"
                            className="img-fluid"
                          />
                        </div>
                        <span className="tr-title">
                          <a href="#">Project Manager</a>
                          <span>
                            <a href="#">Dig File</a>
                          </span>
                        </span>
                        <ul className="tr-list job-meta">
                          <li>
                            <span>
                              <i
                                className="fa fa-map-signs"
                                aria-hidden="true"
                              />
                            </span>
                            Lahore, Punjab, Pakistan
                          </li>
                          <li>
                            <span>
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                            </span>
                            Mid Level
                          </li>
                          <li>
                            <span>
                              <i className="fa fa-money" aria-hidden="true" />
                            </span>
                            $5,000 - $6,000
                          </li>
                        </ul>
                        <div className="time">
                          <a href="#">
                            <span>Full Time</span>
                          </a>
                          {/* <span className="pull-right">
                            Posted 23 hours ago
                          </span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="job-item">
                      <div className="item-overlay">
                        <div className="job-info">
                          <a href="#" className="btn btn-primary">
                            Freelance
                          </a>
                          <span className="tr-title">
                            <a href="#">Graphic Designer</a>
                            <span>
                              <a href="#">Humanity Creative</a>
                            </span>
                          </span>
                          <ul className="tr-list job-meta">
                            <li>
                              <i
                                className="fa fa-map-signs"
                                aria-hidden="true"
                              />
                              Lahore, Punjab, Pakistan
                            </li>
                            <li>
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                              Mid Level
                            </li>
                            <li>
                              <i className="fa fa-money" aria-hidden="true" />
                              $5,000 - $6,000
                            </li>
                          </ul>
                          <ul className="job-social tr-list">
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-heart-o"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-expand"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-bookmark-o"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-long-arrow-right"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="job-info">
                        <div className="company-logo">
                          <img
                            src="./images/sodapdf-converted.png"
                            alt="images"
                            className="img-fluid"
                          />
                        </div>
                        <span className="tr-title">
                          <a href="#">Graphic Designer</a>
                          <span>
                            <a href="#">Humanity Creative</a>
                          </span>
                        </span>
                        <ul className="tr-list job-meta">
                          <li>
                            <span>
                              <i
                                className="fa fa-map-signs"
                                aria-hidden="true"
                              />
                            </span>
                            Lahore, Punjab, Pakistan
                          </li>
                          <li>
                            <span>
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                            </span>
                            Mid Level
                          </li>
                          <li>
                            <span>
                              <i className="fa fa-money" aria-hidden="true" />
                            </span>
                            $5,000 - $6,000
                          </li>
                        </ul>
                        <div className="time">
                          <a href="#">
                            <span className="freelance">Freelance</span>
                          </a>
                          {/* <span className="pull-right">Posted 10 day ago</span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="job-item">
                      <div className="item-overlay">
                        <div className="job-info">
                          <a href="#" className="btn btn-primary">
                            Full Time
                          </a>
                          <span className="tr-title">
                            <a href="#">Design Consultant</a>
                            <span>
                              <a href="#">Families</a>
                            </span>
                          </span>
                          <ul className="tr-list job-meta">
                            <li>
                              <i
                                className="fa fa-map-signs"
                                aria-hidden="true"
                              />
                              Lahore, Punjab, Pakistan
                            </li>
                            <li>
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                              Mid Level
                            </li>
                            <li>
                              <i className="fa fa-money" aria-hidden="true" />
                              $5,000 - $6,000
                            </li>
                          </ul>
                          <ul className="job-social tr-list">
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-heart-o"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-expand"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-bookmark-o"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-long-arrow-right"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="job-info">
                        <div className="company-logo">
                          <img
                            src="./images/sodapdf-converted.png"
                            alt="images"
                            className="img-fluid"
                          />
                        </div>
                        <span className="tr-title">
                          <a href="#">Design Consultant</a>
                          <span>
                            <a href="#">Families</a>
                          </span>
                        </span>
                        <ul className="tr-list job-meta">
                          <li>
                            <span>
                              <i
                                className="fa fa-map-signs"
                                aria-hidden="true"
                              />
                            </span>
                            Lahore, Punjab, Pakistan
                          </li>
                          <li>
                            <span>
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                            </span>
                            Mid Level
                          </li>
                          <li>
                            <span>
                              <i className="fa fa-money" aria-hidden="true" />
                            </span>
                            $5,000 - $6,000
                          </li>
                        </ul>
                        <div className="time">
                          <a href="#">
                            <span>Full Time</span>
                          </a>
                          {/* <span className="pull-right">
                            Posted Oct 09, 2017
                          </span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="job-item">
                      <div className="item-overlay">
                        <div className="job-info">
                          <a href="#" className="btn btn-primary">
                            Freelance
                          </a>
                          <span className="tr-title">
                            <a href="#">Design Associate</a>
                            <span>
                              <a href="#">Pencil</a>
                            </span>
                          </span>
                          <ul className="tr-list job-meta">
                            <li>
                              <i
                                className="fa fa-map-signs"
                                aria-hidden="true"
                              />
                              Lahore, Punjab, Pakistan
                            </li>
                            <li>
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                              Mid Level
                            </li>
                            <li>
                              <i className="fa fa-money" aria-hidden="true" />
                              $5,000 - $6,000
                            </li>
                          </ul>
                          <ul className="job-social tr-list">
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-heart-o"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-expand"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-bookmark-o"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-long-arrow-right"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="job-info">
                        <div className="company-logo">
                          <img
                            src="./images/sodapdf-converted.png"
                            alt="images"
                            className="img-fluid"
                          />
                        </div>
                        <span className="tr-title">
                          <a href="#">Design Associate</a>
                          <span>
                            <a href="#">Pencil</a>
                          </span>
                        </span>
                        <ul className="tr-list job-meta">
                          <li>
                            <span>
                              <i
                                className="fa fa-map-signs"
                                aria-hidden="true"
                              />
                            </span>
                            Lahore, Punjab, Pakistan
                          </li>
                          <li>
                            <span>
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                            </span>
                            Mid Level
                          </li>
                          <li>
                            <span>
                              <i className="fa fa-money" aria-hidden="true" />
                            </span>
                            $5,000 - $6,000
                          </li>
                        </ul>
                        <div className="time">
                          <a href="#">
                            <span className="freelance">Freelance</span>
                          </a>
                          {/* <span className="pull-right">
                            Posted 23 hours ago
                          </span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="job-item">
                      <div className="item-overlay">
                        <div className="job-info">
                          <a href="#" className="btn btn-primary">
                            Part Time
                          </a>
                          <span className="tr-title">
                            <a href="#">Design Consultant</a>
                            <span>
                              <a href="#">Owl</a>
                            </span>
                          </span>
                          <ul className="tr-list job-meta">
                            <li>
                              <i
                                className="fa fa-map-signs"
                                aria-hidden="true"
                              />
                              Lahore, Punjab, Pakistan
                            </li>
                            <li>
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                              Mid Level
                            </li>
                            <li>
                              <i className="fa fa-money" aria-hidden="true" />
                              $5,000 - $6,000
                            </li>
                          </ul>
                          <ul className="job-social tr-list">
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-heart-o"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-expand"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-bookmark-o"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-long-arrow-right"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="job-info">
                        <div className="company-logo">
                          <img
                            src="./images/sodapdf-converted.png"
                            alt="images"
                            className="img-fluid"
                          />
                        </div>
                        <span className="tr-title">
                          <a href="#">Design Consultant</a>
                          <span>
                            <a href="#">Owl</a>
                          </span>
                        </span>
                        <ul className="tr-list job-meta">
                          <li>
                            <span>
                              <i
                                className="fa fa-map-signs"
                                aria-hidden="true"
                              />
                            </span>
                            Lahore, Punjab, Pakistan
                          </li>
                          <li>
                            <span>
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                            </span>
                            Mid Level
                          </li>
                          <li>
                            <span>
                              <i className="fa fa-money" aria-hidden="true" />
                            </span>
                            $5,000 - $6,000
                          </li>
                        </ul>
                        <div className="time">
                          <a href="#">
                            <span className="part-time">Part Time</span>
                          </a>
                          {/* <span className="pull-right">Posted 10 day ago</span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="job-item">
                      <div className="item-overlay">
                        <div className="job-info">
                          <a href="#" className="btn btn-primary">
                            Full Time
                          </a>
                          <span className="tr-title">
                            <a href="#">Project Manager</a>
                            <span>
                              <a href="#">Sky Creative</a>
                            </span>
                          </span>
                          <ul className="tr-list job-meta">
                            <li>
                              <i
                                className="fa fa-map-signs"
                                aria-hidden="true"
                              />
                              Lahore, Punjab, Pakistan
                            </li>
                            <li>
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                              Mid Level
                            </li>
                            <li>
                              <i className="fa fa-money" aria-hidden="true" />
                              $5,000 - $6,000
                            </li>
                          </ul>
                          <ul className="job-social tr-list">
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-heart-o"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-expand"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-bookmark-o"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="fa fa-long-arrow-right"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="job-info">
                        <div className="company-logo">
                          <img
                            src="./images/sodapdf-converted.png"
                            alt="images"
                            className="img-fluid"
                          />
                        </div>
                        <span className="tr-title">
                          <a href="#">Project Manager</a>
                          <span>
                            <a href="#">Sky Creative</a>
                          </span>
                        </span>
                        <ul className="tr-list job-meta">
                          <li>
                            <span>
                              <i
                                className="fa fa-map-signs"
                                aria-hidden="true"
                              />
                            </span>
                            Lahore, Punjab, Pakistan
                          </li>
                          <li>
                            <span>
                              <i
                                className="fa fa-briefcase"
                                aria-hidden="true"
                              />
                            </span>
                            Mid Level
                          </li>
                          <li>
                            <span>
                              <i className="fa fa-money" aria-hidden="true" />
                            </span>
                            $5,000 - $6,000
                          </li>
                        </ul>
                        <div className="time">
                          <a href="#">
                            <span>Full Time</span>
                          </a>
                          {/* <span className="pull-right">Posted 1 day ago</span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /.row */}
              </div>
              {/* /.tab-pane */}
            </div>
          </div>
          {/* /.job-tab */}
        </div>
        {/* /.container */}
      </div>
    </>
  );
}

export default Career;

import React from "react";
import Weworkwith from "./Weworkwith";
import WhyChooseQto from "./WhyChooseQto";

export default function AboutUS() {
  return (
    <>
      <div className="about about-us-img">
        <div className="colorgradientonimage">
          <h2>Welcome to QTO Solutions</h2>
        </div>
      </div>

      <div className="about-us">
        <img className="hat" alt="" src="./images/hart 2.png" />

        <p>
          <h2 className="pageheading-test pageheading-contact">About Us</h2>
          Welcome to QTO Solutions, your trusted partner in the construction
          industry. We are a leading provider of pre-construction bidding
          services, streamlining operations for general contractors, delivering
          cost-competitive estimates, and helping contractors win bids with our
          innovative solutions. With a strong presence in New York State and
          territories, we are committed to professionalism, accuracy, and
          customer satisfaction.
          <br />
          <br />
          At QTO Solutions, we have a team of versatile estimators and advanced
          software tools that enable us to provide online services to the
          highest professional standards. We take pride in protecting the best
          interests of our customers and approach each task with absolute
          responsibility and care. With our unsurpassed professional estimating
          and take-off services, we strive to exceed your expectations.
          <br />
          <br />
          Our skilled team utilizes the latest software in the industry to
          ensure the most accurate cost estimates for your projects. We
          understand the importance of time and efficiency, which is why our
          clients enjoy the fastest turnaround time in the industry at fees that
          are extremely competitive. As a team of specialized individuals with
          different skill sets, we are dedicated to staying at the forefront of
          construction estimation innovations, providing you with the latest
          industry insights.
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <div className="pageheading">CSI Divisions</div>
          <div className="csidivsions csdaboutus container">
            <ul>
              <li>
                <span>01&nbsp;</span>-&nbsp;<span>General Requirnments</span>
              </li>
              <li>
                <span>02&nbsp;</span>-&nbsp;<span>Existing Conditions</span>
              </li>
              <li>
                <span>03&nbsp;</span>-&nbsp;<span>Concrete</span>
              </li>
              <li>
                <span>04&nbsp;</span>-&nbsp;<span>Masonry</span>
              </li>
              <li>
                <span>05&nbsp;</span>-&nbsp;<span>Metals</span>
              </li>
              <li>
                <span>06&nbsp;</span>-&nbsp;
                <span>Wood, Plastics, Composites</span>
              </li>
              <li>
                <span>07&nbsp;</span>-&nbsp;
                <span>Thermal & Moisture Protection</span>
              </li>
              <li>
                <span>08&nbsp;</span>-&nbsp;<span>Openings</span>
              </li>
              <li>
                <span>09&nbsp;</span>-&nbsp;<span>Finishes</span>
              </li>
              <li>
                <span>10&nbsp;</span>-&nbsp;<span>Specialties</span>
              </li>
              <li>
                <span>11&nbsp;</span>-&nbsp;<span>Equipment</span>
              </li>
              <li>
                <span>12&nbsp;</span>-&nbsp;<span>Furnishings</span>
              </li>
              <li>
                <span>13&nbsp;</span>-&nbsp;<span>Special Construction</span>
              </li>
              <li>
                <span>14&nbsp;</span>-&nbsp;<span>Conveying Equipment</span>
              </li>
              <li>
                <span>15&nbsp;</span>-&nbsp;<span>Fire Suppression</span>
              </li>
              <li>
                <span>16&nbsp;</span>-&nbsp;<span>Plumbing</span>
              </li>
              <li>
                <span>17&nbsp;</span>-&nbsp;
                <span>Heating, Ventilating, & Air Conditioning (HVAC)</span>
              </li>
              <li>
                <span>18&nbsp;</span>-&nbsp;<span>Integrated Automation</span>
              </li>
              <li>
                <span>19&nbsp;</span>-&nbsp;<span>Integrated Automation</span>
              </li>
              <li>
                <span>20&nbsp;</span>-&nbsp;<span>Integrated Automation</span>
              </li>
              <li>
                <span>21&nbsp;</span>-&nbsp;<span>Integrated Automation</span>
              </li>
              <li>
                <span>22&nbsp;</span>-&nbsp;<span>Integrated Automation</span>
              </li>
              <li>
                <span>23&nbsp;</span>-&nbsp;<span>Integrated Automation</span>
              </li>
              <li>
                <span>24&nbsp;</span>-&nbsp;<span>Integrated Automation</span>
              </li>
              <li>
                <span>25&nbsp;</span>-&nbsp;<span>Integrated Automation</span>
              </li>
              <li>
                <span>26&nbsp;</span>-&nbsp;<span>Integrated Automation</span>
              </li>
              <li>
                <span>27&nbsp;</span>-&nbsp;<span>Integrated Automation</span>
              </li>
              <li>
                <span>28&nbsp;</span>-&nbsp;<span>Integrated Automation</span>
              </li>
            </ul>
          </div>
        </p>

        <img className="cal" alt="" src="./images/Cal 1.png" />
      </div>
      <div className="container-fluid aUp">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"></div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"></div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"></div>
        </div>
      </div>
      <WhyChooseQto />
      <Weworkwith />
      {/* <div className=" WWW">
        <div className="container">
          <h1 className="pageheading">We Work With</h1>
          <p>
            We are a multi-faceted industry leader and through our family of
            companies making up one of the region’s largest Construction
            Estimation Firm.
          </p>
          <ul>
            <li>
              <img className="" alt="" src="./images/Checkmark.png" />
              <p>General Contractors</p>
            </li>
            <li>
              <img className="" alt="" src="./images/Checkmark.png" />
              <p>General Contractors</p>
            </li>
            <li>
              <img className="" alt="" src="./images/Checkmark.png" />
              <p>General Contractors</p>
            </li>

            <li>
              <img className="" alt="" src="./images/Checkmark.png" />
              <p>General Contractors</p>
            </li>
            <li>
              <img className="" alt="" src="./images/Checkmark.png" />
              <p>General Contractors</p>
            </li>
          </ul>
          <button className="NB">Get quote</button>
        </div>
      </div> */}
    </>
  );
}
